import styled from 'styled-components'

import breakpoints from '../constants/breakpoints'

const LegalRowStyled = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 0.5vw;

  @media (max-width: ${breakpoints.screenLG}) {
    padding-left: 0;
  }

  br {
    display: none;

    @media (max-width: ${breakpoints.screenMD}) {
      display: block;
    }
  }

  .legal-links {
    display: flex;
    gap: 1vw;

    @media (max-width: ${breakpoints.screenLG}) {
      gap: 30px;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      gap: 10px;
    }
  }

  a:after {
    display: none;
  }
`

export { LegalRowStyled }
