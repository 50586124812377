import React, { useRef, useEffect } from 'react'

import { theme } from '../constants/theme'

import { ButtonStyled } from '../styles/ButtonStyled'
/**
 * Button with hover transitions.
 * @param {ComponentProps} props
 * @param {Boolean} props.isReverse
 * @param {String} props.color
 * @param {ChildNode} props.children
 * @returns {FunctionComponent}
 */
const Button = ({ isReverse, color = theme.textPrimary, children }) => {
  const btnRef = useRef(null)

  useEffect(() => {
    const animate = function (e) {
      const { offsetX: x, offsetY: y } = e,
        { offsetWidth: width, offsetHeight: height } = this,
        move = 60,
        xMove = (x / width) * (move * 2) - move,
        yMove = (y / height) * (move * 2) - move

      this.style.transform = `translate(${xMove}%, ${yMove}%)`

      if (e.type === 'mouseleave') this.style.transform = ''
    }

    btnRef.current.addEventListener('mousemove', animate)
    btnRef.current.addEventListener('mouseleave', animate)
  }, [])

  return (
    <ButtonStyled ref={btnRef} isReverse={isReverse} color={color}>
      {children}
    </ButtonStyled>
  )
}

export default Button
