import styled from 'styled-components'

import breakpoints from '../constants/breakpoints'

const ButtonStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 0.15vw solid
    ${({ theme, isReverse, color }) => (isReverse ? theme.textLight : color)};
  border-radius: 100%;
  width: 5.2vw;
  height: 5.2vw;
  will-change: transform;
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);

  @media (max-width: ${breakpoints.screenLG}) {
    border-width: 2px;
    width: 80px;
    height: 80px;
  }

  @media (max-width: ${breakpoints.screenSM}) {
    width: 70px;
    height: 70px;
  }

  svg {
    pointer-events: none;
  }
`

export { ButtonStyled }
